/** @jsxImportSource @emotion/react */
import * as React from 'react'
import * as mq from 'utils/media-queries'
import {
	ClientLink,
	ClientButtonContainer,
	ClientHeader,
	ClientPhone,
	ClientSection,
	Column,
	Container,
	FAQQuestion,
	FAQTitle,
	FlexColumn,
	FlexRow,
	Image,
	InfoContent,
	InfoContentContainer,
	InfoHeader,
	TitleContainer,
	TitleContent,
	TitleHeader,
	TitleImageContainer,
	TitleSection,
	ClientPhoneContainer,
} from 'utils/lib'
import trainingBanner from 'assets/training-banner.jpg'
import trainingSpaniel from 'assets/training-service.jpg'
import trainingVector from 'assets/training-vector.jpg'
import puppyClass from 'assets/training-puppy-class.jpg'
import beginnerClass from 'assets/training-beginner-class.jpg'
import training1 from 'assets/training-1.jpg'
import training2 from 'assets/training-2.jpg'
import evalLogo from 'assets/evaluator-logo.png'

function TrainingTitle() {
	return (
		<TitleSection
			css={{
				backgroundRepeat: 'no-repeat',
				backgroundSize: 'cover',
			}}
		>
			<TitleImageContainer src={trainingBanner} alt="training" left="-130%" />
			<TitleContainer
				css={{
					[mq.extraSmall]: {
						width: '100%',
					},
				}}
			>
				<TitleHeader>Dog Training</TitleHeader>
				<TitleContent>
					Training builds confidence, provides mental stimulation and
					strengthens the bond with your pet.
				</TitleContent>
			</TitleContainer>
		</TitleSection>
	)
}

function TrainingGeneralInformation() {
	return (
		<InfoContentContainer>
			<FlexRow>
				<FlexColumn orientation="left">
					<Image src={trainingSpaniel} alt="dogt-sit" />
				</FlexColumn>
				<FlexColumn
					orientation="right"
					css={{
						[mq.extraSmall]: {
							marginBottom: '2rem',
						},
						[mq.small]: {
							marginBottom: '2rem',
						},
					}}
				>
					<InfoHeader data-aos="fade-in">Training Service</InfoHeader>
					<InfoContent data-aos="fade-in" data-aos-delay="100">
						We offer obedience training for your pet in a variety of ways. We
						can accommodate training for drop-offs, during daycare and boarding,
						and AKC certification and more. Your pet will work one on one with a
						trainer and/or in a group setting.
					</InfoContent>

					<InfoContent
						data-aos="fade-in"
						data-aos-delay="100"
						css={{marginTop: '1rem'}}
					>
						Due to the safety of our boarding/daycare guests and staff, we do
						not train aggressive dogs.
					</InfoContent>
				</FlexColumn>
			</FlexRow>

			<Column>
				<div
					css={{
						textAlign: 'center',
					}}
				>
					<img
						css={{
							width: '100%',
							margin: '4rem auto',
							maxWidth: '400px',
							[mq.extraSmall]: {
								margin: '2rem auto',
							},
							[mq.small]: {
								margin: '2rem auto',
							},
						}}
						src={evalLogo}
						alt="evaluator-logo"
					/>
				</div>
			</Column>

			<FlexRow>
				<FlexColumn orientation="left">
					<InfoHeader>S.T.A.R. Puppy Class</InfoHeader>
					<InfoContent>
						Socialization, Training, Activity, Responsibility (S.T.A.R.) is a 6
						week group training class for puppies 1 year or less designed to get
						owners and their puppies familiar with the basics of training and
						how to be social with other dogs. It is also a good lead in to
						earning the Canine Good Citizen title. Upon completing the course,
						you and your puppy will take a test given by your instructor. Upon
						completing the course and passing the test, your dog will be
						eligible to apply for the S.T.A.R. Puppy Certificate. In addition,
						you and your puppy will be listed in the AKC S.T.A.R. puppy records.
					</InfoContent>
				</FlexColumn>

				<FlexColumn orientation="right">
					<InfoHeader>Canine Good Citizen (CGC)</InfoHeader>
					<InfoContent>
						The CGC program is a 10-skill test that teaches good manners to dogs
						and responsible dog ownership to their owners. We offer hourly
						training to help your dog reach their goals and pass the test. If
						your dog already has the training needed to pass, you can schedule
						an appointment with an AKC approved evaluator and we will conduct
						the evaluation on-site. Upon completing and passing the test, your
						dog will be eligible to apply for the CGC Title and Certificate from
						AKC.
					</InfoContent>
				</FlexColumn>
			</FlexRow>

			<FlexRow>
				<FlexColumn orientation="left">
					<InfoHeader>AKC Urban CGC (CGCU)</InfoHeader>
					<InfoContent>
						For owners looking to further their dogs training, the CGCU is the
						more advanced portion of the CGC Program. The test requires that it
						be administered in a urban or public environment, meant to implement
						distractions like vehicles, people, and noises. It does require that
						all dogs being evaluated for the CGCU certification must already
						have a CGC certificate. We offer training to help assist you in
						meeting your goal, or if your dog already has the training needed to
						pass, you can schedule an appointment with a AKC approved evaluator
						to take the test. Upon completing and passing the test, your dog
						will be eligible to apply for the CGCU Title and Certificate from
						AKC.
					</InfoContent>
				</FlexColumn>
			</FlexRow>

			<Column>
				<InfoHeader data-aos="fade-in">Daycare Training</InfoHeader>
				<InfoContent
					data-aos="fade-in"
					data-aos-delay="100"
					css={{
						[mq.extraSmall]: {
							marginBottom: '1rem',
						},
						[mq.small]: {
							marginBottom: '1rem',
						},
					}}
				>
					With our daycare and training options, your pet can enjoy playtime and
					receive training while you are out.
				</InfoContent>
			</Column>

			<FlexRow>
				<FlexColumn orientation="left">
					<Image data-aos="fade-in" src={beginnerClass} alt="dog-ball" />
					<InfoHeader css={{marginTop: '1rem'}}>
						Beginner and Novice Classes
					</InfoHeader>
					<InfoContent data-aos="fade-in">
						The beginner's course will build a training foundation for your dog.
						While learning basic commands, they will learn what is expected of
						them and acceptable behavior.
					</InfoContent>
					<InfoContent data-aos="fade-in" css={{marginTop: '1rem'}}>
						The novice course will add onto what your dog has learned in the
						beginner's course. Your dog will continue to improve on the previous
						commands while adding more advanced ones. This course is more
						challenging and requires your pet complete the beginner's course.
					</InfoContent>
				</FlexColumn>
				<FlexColumn orientation="right">
					<Image data-aos="fade-in" src={puppyClass} alt="dog-sit" />
					<InfoHeader data-aos="fade-in" css={{marginTop: '1rem'}}>
						Group Puppy Classes
					</InfoHeader>
					<InfoContent data-aos="fade-in">
						It is recommended that puppies be exposed to groups of dogs early as
						possible so they can build positive social interaction. The puppy
						class will help them by providing a safe environment to learn
						healthy socialization skills and will be an introduction to
						training.
					</InfoContent>
					<InfoContent data-aos="fade-in" css={{marginTop: '1rem'}}>
						The owner will also learn how to enforce good behavior and
						redirection, as well as the basics like proper leash holding and
						commands. Whether this is your first puppy and want to learn the
						basics, or you just want your puppy to learn good habits, this class
						can help.
					</InfoContent>
				</FlexColumn>
			</FlexRow>
		</InfoContentContainer>
	)
}

function TrainingFAQs() {
	return (
		<InfoContentContainer data-aos="fade-in">
			<Column>
				<FAQTitle />
				<FAQQuestion
					question={`When can my dog begin training?`}
					answer={`We require that dogs have all their vaccinations before starting training. We also require that all
          dogs that are 6 months and older have a social evaluation by one of our on-site staff.
          `}
				/>
				<FAQQuestion
					question={`Will my dog enjoy training?`}
					answer={`Your dog will enjoy the challenges and praise they will receive from completing various tasks and commands.`}
				/>
				<FAQQuestion
					question={`What will my dog learn?`}
					answer={`Your dog will learn how to perform a variety of commands, both verbal and hand signals.`}
				/>
				<FAQQuestion
					question={`How long is a training session?`}
					answer={`Our sessions are 1 hour long and are conducted on-site.`}
				/>
				<FAQQuestion
					question={`How much training will my dog need?`}
					answer={`Every dog is different in how fast they are able to learn. Some dogs will need more training than others as many factors affect their ability to learn such as prior training, age, breed and habits.`}
				/>
				<FAQQuestion
					question={`Do you use shock collars for training?`}
					answer={`We do not use any prong, shock, or spray collars for training.`}
				/>
			</Column>
		</InfoContentContainer>
	)
}

function Gallery() {
	return (
		<InfoContentContainer>
			<FlexRow>
				<FlexColumn orientation="left">
					<Image src={training1} alt="training-1" />
				</FlexColumn>

				<FlexColumn orientation="right">
					<Image src={training2} alt="training-2" />
				</FlexColumn>
			</FlexRow>
		</InfoContentContainer>
	)
}

function TrainingScreen() {
	React.useEffect(() => {
		// window.scrollTo(0, 0)
	}, [])

	return (
		<Container>
			<TrainingTitle />
			<ClientSection>
				<ClientHeader>BOOK YOUR APPOINTMENT</ClientHeader>
				<ClientButtonContainer>
					<ClientLink to="/clients/new">New Client Application</ClientLink>
					<ClientLink to="https://pawpartner.com/pz/book?p=268457">
						Returning Client
					</ClientLink>
					<ClientPhoneContainer>
						<ClientPhone />
					</ClientPhoneContainer>
				</ClientButtonContainer>
			</ClientSection>

			<TrainingGeneralInformation />
			<InfoContent>
				<img
					css={{
						margin: '0 auto',
						maxWidth: '600px',
						[mq.extraSmall]: {
							maxWidth: '100%',
						},
						[mq.small]: {
							maxWidth: '400px',
						},
						[mq.medium]: {
							maxWidth: '500px',
						},
						[mq.large]: {
							marginTop: '4rem',
							marginBottom: '4rem',
						},
					}}
					src={trainingVector}
					alt="training-vector"
				/>
			</InfoContent>
			<TrainingFAQs />

			<Gallery />
		</Container>
	)
}

export {TrainingScreen}
